import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', {
  state: () => {
    return {
      showModal: null as string | null,
      tab: 'campaigns' as string,
      chainId: "",
      loggedIn: false,
      web3AuthLoggedIn: false,
      loading: false,
      minting: false,
      approved: false,
      ocConnected: false,
      ocAccessToken: "",
      ocAchievementId: "",
    };
  },
  getters: {
    getShowModal: (state) => state.showModal,
    getTab: (state) => state.tab,
    getChainId: (state) => state.chainId,
    getLoggedIn: (state) => state.loggedIn,
    getWeb3AuthLoggedIn: (state) => state.web3AuthLoggedIn,
    isLoading: (state) => state.loading,
    isMinting: (state) => state.minting,
    isApproved: (state) => state.approved,
    getOcConnected: (state) => state.ocConnected,
    getOcAccessToken: (state) => state.ocAccessToken,
    getOcAchievementId: (state) => state.ocAchievementId,
  },
  actions: {
    setShowModal(modalName: string | null = null) {
      this.showModal = modalName;
    },
    setTab(tab: 'campaigns' | 'surveys') {
      this.tab = tab;
    },
    setChainId(chainId: string) {
      this.chainId = chainId;
    },
    setLoggedIn(value: boolean) {
      this.loggedIn = value;
    },
    setWeb3AuthLoggedIn(value: boolean) {
      this.web3AuthLoggedIn = value;
    },
    setLoading(value: boolean) {
      this.loading = value;
    },
    setMinting(value: boolean) {
      this.minting = value;
    },
    setApproved(value: boolean) {
      this.approved = value;
    },
    setOcConnected(value: boolean) {
      this.ocConnected = value;
    },
    setOcAccessToken(value: string) {
      this.ocAccessToken = value;
    },
    setOcAchievementId(value: string) {
      this.ocAchievementId = value;
    },
  },
});
